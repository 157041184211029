import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Kaushalambreadcreumb from "../components/retreats/kaushlam-breadcumb";

import Kaushalambannar from "../components/retreats/kaushlam-banner";

import Onlineprogramsec from "../components/retreats/online-program";

import Aboutkauslam from "../components/retreats/about-kauslam";

import Programbenefits from "../components/retreats/program-benefits";

import Kutumaprivailage from "../components/retreats/kutuma-privilage";

import RetreatTestimonailslider from "../components/retreats/retreat-testimonial";

import Somebeneficalslider from "../components/retreats/some-beneficals";

import Programfaclity from "../components/retreats/program-faclity";

import Investments from "../components/retreats/investments";

// markup
const KaushalamPage = () => {
  return (
    <div>
      <Seo title="Kaushalam" />

      <Layout isSticky>
        <section>
          <Kaushalambreadcreumb />
        </section>

        <section>
          <Kaushalambannar />
        </section>

        <section>
          <Onlineprogramsec />
        </section>

        <section>
          <Aboutkauslam />
        </section>

        <section>
          <Programbenefits />
        </section>

        <section>
          <Kutumaprivailage />
        </section>

        <section>
          <RetreatTestimonailslider />
        </section>

        <section>
          <Somebeneficalslider />
        </section>

        <section>
          <Programfaclity />
        </section>

        <section>
          <Investments />
        </section>
      </Layout>
    </div>
  );
};

export default KaushalamPage;
