import React from "react";

import phonewhite from "../../assets/images/retreats/phone-call.png";

import cellicon from "../../assets/images/retreats/cell.png";

import padlock from "../../assets/images/retreats/padlock.png";

import guruloc from "../../assets/images/retreats/guru.png";

export default function Kutumaprivailage() {
  return (
    <section className="kutumba-privailage-wraper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper" style={{ textAlign: "left" }}>
              {" "}
              Heartfulness{" "}
              <span className="theme-color"> Kutumba Privileges </span>{" "}
            </h3>
          </div>
        </div>
        <div class="row w-100 mx-0">
          <div class="col-lg-3 col-md-4 col-sm-6 px-2 col-xs-12 mb-4">
            <div class="Privileges-card border box-shadow d-flex card px-4 pt-4 pb-0 mb-3 w-100 h-100">
              <div className="phone-icon-bg">
              <img
                className="img-fluid"
                src={phonewhite}
                alt="Phone"
              />
              </div>
              <div class="pt-3">
                <p class="mb-0 gotham-book theme-p-tag">On call, Heartfulness Trainer</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 px-2 col-xs-12 mb-4">
            <div class="Privileges-card border box-shadow d-flex card px-4 pt-4 pb-0  mb-3 w-100 h-100">
              <div className="cell-bg">
              <img
                className="img-fluid"
                src={cellicon}
                alt="Phone"
              />
              </div>
              <div class="px-2 pt-3">
                <p class="mb-0 gotham-book theme-p-tag">Mobile app to practice tools </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 px-2 col-xs-12 mb-4">
            <div class="Privileges-card border box-shadow d-flex card px-4 pt-4 pb-0  mb-3 w-100 h-100">
              <div className="yellow-lock">
              <img
                className="img-fluid"
                src={padlock}
                alt="Phone"
              />
              </div>
              <div class="pt-3">
                <p class="mb-0 gotham-book theme-p-tag">
                  Access to Library and Day Facilities in 500+ Heartfulness
                  centres globally
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 px-2 col-xs-12 mb-4">
            <div class="Privileges-card border box-shadow d-flex card px-4 pt-4 pb-0  mb-3 w-100 h-100">
              <div className="guru-light">
              <img
                className="img-fluid"
                src={guruloc}
                alt="Phone"
              />
              </div>
              <div class="pt-3">
                <p class="mb-0 gotham-book theme-p-tag">
                  3 to 7 days contemplation retreat with family at centres in
                  Denmark, France, USA, Germany, Malaysia &amp; India
                  (Himalayas, Kerala, Pune, Kharagpur)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
