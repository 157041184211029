import React from "react";

import { Link } from "gatsby";

export default function Investments() {
  return (
    <section className="investment-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper"> Investment </h3>
            <span class="vertical-blue-line"> </span>
            <p className="theme-p-tag pad-top20-safari">
              {" "}
              Your investment into this program will be your decision and we
              will not determine it. You may choose an amount a normal online
              program of such quality would entail, or you may choose more,
              less, or even zero.
            </p>
          </div>
        </div>
      </div>

      <div class="container contact-us text-center my-4">
        <div class="p-4 bg-blue-grad">
          <h3 class="heading-wrapper">Contact Us</h3>
          <div class="row mb-3 align-items-center">
            <div class="col-md-6 col-sm-12 d-flex justify-content-center borde-right-line">
              <p class="gotham-light">Toll Free number (India)</p>
              <div class="px-4 py-2 max-content">
                <h6 class="gotham-book contact-color mb-0">
                  <a class="btn contact-color link-color btn-pad-ony" href="tel:1800121DHYANA3492">
                    1 800 121 DHYANA (3492)
                  </a>
                </h6>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 d-flex justify-content-center borde-right-line" style={{borderRight: 'none'}}>
              <p class="gotham-light">
                For further information email us at
              </p>
              <div class="px-4 py-2 max-content">
                <h6 class="gotham-book contact-color mb-0">
                  <Link
                    class="btn contact-color link-color btn-pad-ony"
                    to="mailto:kaushalam@heartfulness.org"
                  >
                    kaushalam@heartfulness.org
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
