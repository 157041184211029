import React from "react";

import iconbrain from "../../assets/images/retreats/Group-1343.png";

import mansun from "../../assets/images/retreats/Group-1340.png";

import heartshake from "../../assets/images/retreats/Group-1346.png";

import bonding from "../../assets/images/retreats/Group-1349.png";

import eagleman from "../../assets/images/retreats/Group-1350.png";

import hfnflower from "../../assets/images/retreats/hfn-flower-logo.png";

export default function Programbenefits() {
  return (
    <section className="program-benefits-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper" style={{textAlign: 'left'}}>
              {" "}
              Program <span className="theme-color"> Benefits </span>{" "}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={iconbrain}
                alt="Wellness banner"
              />
              <div class="pl-2 pr-0">
                <p class="gotham-book mb-0 text-benefits">
                  Move from burnout to joy
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={mansun}
                alt="Wellness banner"
              />
              <div class="pl-2 pr-0">
                <p class="gotham-light mb-0 text-benefits">
                  Energize your time &amp; your self
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={heartshake}
                alt="Wellness banner"
              />
              <div class="pl-2 pr-0">
                <p class="gotham-light mb-0">
                  Enhance your Work &amp; Personal Relationship
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={bonding}
                alt="Realign Priorities"
              />
              <div class="pl-2 pr-0">
                <p class="gotham-light mb-0">
                  Realign Priorities for Well-being &amp; Fulfilment
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={eagleman}
                alt="Wellness banner"
              />

              <div class="pl-2 pr-0">
                <p class="gotham-light mb-0">
                  Get Inspired by Yourself: Be your Own Hero
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div class="benefit-card card mb-3 p-3 h-100 align-items-center justify-content-flex-start flex-direction-row">
              <img
                className="img-fluid wid46pic"
                src={hfnflower}
                alt="Realign Priorities"
              />

              <div class="pl-2 pr-0">
                <p class="gotham-light mb-0">
                  Join the Heartfulness Kutumba – family
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
