import React from "react";

// import kaushalamretreat from "../../assets/images/retreats/hfn-kaushalam-retreats-img.png";

import { Link } from "gatsby";

export default function Kaushalambannar() {
  return (
    <section className="kaushalam-banner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="kaushalam-content-banner">
              <h1 class="gotham-medium display-4 theme-color"> <b> Kaushalam </b></h1>
              <h2 class="gotham-medium">Excellence in Action</h2>
              <h6 class="gotham-medium mg-bottom20" style={{fontWeight:'400'}}>
                a unique online experience which brings out the best in you
              </h6>
              <p>
                <Link
                  id="dwdbtn"
                  class="btn btn-blue"
                  to="https://cdn-prod.heartfulness.org/hfn/newsletters/2022/OnlineKaushalam-A4Brochure-11JAN22.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="button"
                >
                  Download Brochure
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
