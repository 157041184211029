import React from "react";

import subramanians from "../../assets/images/retreats/kaushalam-subramanians-fac-img.png";

import deepak from "../../assets/images/retreats/kaushalam-Deepak-fac-img.png";

import ragahav from "../../assets/images/retreats/kaushalam-Raghav-fac-img.png";

import harpreet from "../../assets/images/retreats/Harpreet.png";

import deepkavarma from "../../assets/images/retreats/deepak_varma.jpg";

export default function Programfaclity() {
  return (
    <section className="program-faclity-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper"> Program Faculty</h3>
            <span class="vertical-blue-line"> </span>
          </div>
        </div>
      </div>

      <div class="container pb-5">
        <div class="row justify-content-center mx-2">
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
            <div class="Teacher-card card w-100 h-100 align-items-center justify-content-flex-start">
              <div>
              <img
                className="img-fluid"
                src={subramanians}
                alt="faculty"
              />
              </div>
              <div class="d-flex w-100 h-100 align-items-center justify-content-center bg-blue-gradient">
                <p class="gotham-light mb-0 text-center">
                  Subramanian Sankaran
                </p>
              </div>
              <div class="Faculty-card center">
                <p>
                  CEO of Management Consulting Firm and Advisor/Director in
                  multiple companies. Certified Executive Coach: Trustee in
                  multiple NGOs. Heartfulness Trainer 16+ years
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
            <div class="Teacher-card card w-100 h-100 align-items-center justify-content-flex-start">
              <div>
              <img
                className="img-fluid"
                src={deepak}
                alt="faculty"
              />
              </div>
              <div class="d-flex w-100 h-100 align-items-center justify-content-center bg-blue-gradient">
                <p class="gotham-light mb-0 text-center">Deepak Saini</p>
              </div>
              <div class="Faculty-card center">
                <p>
                  Product leader with close to two decades of association with
                  corporates and start-ups, currently employed as VP, Product
                  Delight at Games24x7. Runs several entrepreneurial ventures
                  and works with the Academia to shape young minds; An IIT
                  Bombay alumnus; Heartfulness Trainer 5+ yrs
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
            <div class="Teacher-card card w-100 h-100 align-items-center justify-content-flex-start">
              <div>
              <img
                className="img-fluid"
                src={ragahav}
                alt="faculty"
              />
              </div>
              <div class="d-flex w-100 h-100 align-items-center justify-content-center bg-blue-gradient">
                <p class="gotham-light mb-0 text-center">Raghav Rao</p>
              </div>
              <div class="Faculty-card center">
                <p>
                  Senior Scientist at Corporate GE R&amp;D with two decades of
                  experience in Aviation and Energy technologies.; A Life and
                  Leadership coach; Supports neuroscience-based education
                  initiatives and habit-formation to support youth &amp; adults
                  in discovering/actualizing their inner potential; An IITM
                  &amp; UCSD Alumnus; Heartfulness Trainer 5+ yrs
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mx-2">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4">
              <div class="Teacher-card card w-100 h-100 align-items-center justify-content-flex-start">
                <div>
                <img
                className="img-fluid"
                src={harpreet}
                alt="faculty"
              />
                </div>
                <div class="d-flex w-100 h-100 align-items-center justify-content-center bg-blue-gradient">
                  <p class="gotham-light mb-0 text-center">Harpreet Bhan</p>
                </div>
                <div class="Faculty-card center">
                  <p>
                    VP Adizes India and Middle East – top 10 large business
                    consulting group. Ex board member responsible for 43
                    countries of multibillion global security services company.
                    Presented entire season of Radio City – Shanti Sutra.
                    Heartfulness Trainer 15+ Years.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4">
              <div class="Teacher-card card w-100 h-100 align-items-center justify-content-flex-start">
                <div>
                <img
                className="img-fluid"
                src={deepkavarma}
                alt="faculty"
              />
                </div>
                <div class="d-flex w-100 h-100 align-items-center justify-content-center bg-blue-gradient">
                  <p class="gotham-light mb-0 text-center">Deepak Varma</p>
                </div>
                <div class="Faculty-card center">
                  <p>
                    Founder Director of Technospirit focusing on Leadership
                    training. Winner of Asia Pacific Excellence award from HP;
                    President at Smartcity Toastmasters; Speaker at NASSCOM
                    summit; Rich experience of working with CXOs in Telecom and
                    IT. Heartfulness Trainer 20+ years.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="theme-p-tag cent-item">
            Note: There will be an additional dynamic faculty pool depending on
            availability for each unique session
          </p>
        </div>
      </div>
    </section>
  );
}
