import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

// import quoteleft from "../../assets/images/testimonial-slider/awesome-quote-left.png";

// import quoteright from "../../assets/images/testimonial-slider/awesome-right.png";

export default function RetreatTestimonailslider() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}
          </button>
          <button onClick={() => next()}>
            {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="testimonial-slider container pad60">
      <div className="row">
        <div className="col-md-12">
          <h3 className="heading-wrapper"> Testimonials </h3>
          <span className="vertical-blue-line"> </span>
        </div>

        <div className="col-md-9 cent-item carousel-section">
          <Carousel
            arrows={true}
            showDots={true}
            autoPlaySpeed={3000}
            draggable
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={true}
            swipeable
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
          >
            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body shadow-none">
                  <p class="testimonialcard-text">
                    {" "}
                    This is an amazing program that you have. all of the
                    presenters were extremely professional, and I could feel the
                    passion as they presented. I feel honoured to have been a
                    part of your program and would invite you and the
                    Heartfulness Institute to come on a regular basis. I know
                    there are many others who would enjoy the program.
                  </p>
                  <h4 className="theme-h4 theme-color"> Dr Hoyt Ross</h4>
                  <p class="testimonialcard-text">
                    {" "}
                    CEO, Promise Hospital, Oxford, FL, USA
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body shadow-none">
                  <p class="testimonialcard-text">
                    {" "}
                    I am short of words as meditation has very positive results.
                    Physicians participating in Heartfulness training report
                    enhanced personal well-being, decreased burnout, and
                    improved attitude towards patient care. I am sure you will
                    also realize a positive change.
                  </p>
                  <h4 className="theme-h4 theme-color"> Dr Mansee Thakur </h4>
                  <p class="testimonialcard-text">
                    {" "}
                    I/C Director, MGM Institute of Health Science, India.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body shadow-none">
                  <p class="testimonialcard-text">
                    {" "}
                    We got the confidence that we can face any problem in our
                    life. The program helped us in thinking in the correct way
                    and understanding the feelings of others. It helped me
                    create a new personality. It helped me listen to my soul
                    rather than my mind. Before this I was a rock, now I am a
                    well with artist&#39;s work filled with values. Thank you for
                    your help in building my character.
                  </p>
                  <h4 className="theme-h4 theme-color">
                    {" "}
                    10th Standard Students{" "}
                  </h4>
                  <p class="testimonialcard-text"> JNV Pedavegi, India </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body shadow-none">
                  <p class="testimonialcard-text">
                    {" "}
                    I have promoted meditation to my patients for years as
                    medical studies show its ability to help rebalance
                    sympathetic/parasympathetic tone. It can lower cortisol
                    levels, reduce blood pressure and improve sleep patterns. I
                    have learned many things from my experience. I would
                    recommend meditation to every living being.
                  </p>
                  <h4 className="theme-h4 theme-color"> Dr Gary Huber </h4>
                  <p class="testimonialcard-text"> Ohio </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
