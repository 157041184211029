import React from "react";

import { Link } from "gatsby";

export default function Onlineprogramsec() {
  return (
    <section className="online-program-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12 cent-item">
             <h4 className="theme-h4">ONLINE PROGRAM IN</h4> 
             <h3 className="heading-wrapper theme-color">PERSONAL MASTERY</h3> 
             <p className="theme-p-tag">for entrepreneurs, executives, professionals and leaders</p>
             <h5 className="theme-h5"> Holistic Wellness and Learning from Experts </h5>
             <h5 className="theme-h5">Registration open for 2022 &#45; Season 3 &#45; Batch 2 starting May 14</h5>
             <h5 className="theme-h5"><b style={{fontWeight:'500'}}>Program Dates </b> :  May 14, 15, 21, 22, 28 &amp; 29 (Saturday and Sunday)</h5>
             <h5 className="theme-h5"><b style={{fontWeight:'500'}}>Time </b>: 11:00AM to 12:30PM &#45; IST</h5>
             <h5 className="theme-h5">Click <Link className="btn link-color btn-pad-ony"  to="https://register.heartfulness.org/index.php?q=civicrm/event/register&amp;reset=1&amp;id=165" target="_blank" rel="noopener noreferrer">here</Link> for registration</h5>
          </div>
        </div>
      </div>
    </section>
  );
}
