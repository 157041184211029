import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import ceoclub from "../../assets/images/retreats/ceo-clubs.png";

import jiologo from "../../assets/images/retreats/jio-logo.png";

import bhartlogo from "../../assets/images/retreats/bhart.png";

import standardlogo from "../../assets/images/retreats/standard.png";

import aiblogo from "../../assets/images/retreats/aib.png";

import tatalogo from "../../assets/images/retreats/tata.png";

import delllogo from "../../assets/images/retreats/dell.png";

import fbooklogo from "../../assets/images/retreats/fbook.png";

import cisflogo from "../../assets/images/retreats/cisf.png";

import ibmlogo from "../../assets/images/retreats/ibm.png";

import eylogo from "../../assets/images/retreats/ey.png";

import fortislogo from "../../assets/images/retreats/fortis.png";

// import quoteright from "../../assets/images/testimonial-slider/awesome-right.png";

export default function Somebeneficalslider() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 6,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}
          </button>
          <button onClick={() => next()}>
            {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="testimonial-slider container pad60">
      <div className="row">
        <div className="col-md-12">
          <h3 className="heading-wrapper"> Some Beneficiaries </h3>
          <span className="vertical-blue-line"> </span>
        </div>

        <div className="carousel-section">
          <Carousel
            arrows={false}
            showDots={true}
            autoPlay={true}
            autoPlaySpeed={1000}
            draggable
            infinite={true}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={1000}
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={true}
            swipeable
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
          >
            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                  <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={ceoclub}
                      alt="Wellness banner"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={jiologo}
                      alt="logo"
                    />
                  </div>
                 
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                 
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={bhartlogo}
                      alt="logo"
                    />
                  </div>
                 
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={fbooklogo}
                      alt="logo"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={standardlogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={aiblogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={tatalogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>


            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={delllogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={cisflogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>


            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={ibmlogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={eylogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body1 shadow-none">
                <div className="box-logo-wrapper">
                    <img
                      className="img-fluid"
                      src={fortislogo}
                      alt="Wellness banner"
                    />
                  </div>
                 
                  
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
