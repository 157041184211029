import React from "react";

import kaushlammeditation from "../../assets/images/retreats/kaushalam-meditation-img.png";

export default function Aboutkauslam() {
  return (
    <div className="about-kauslam-wrapper pad60">
     <div className="container-fluid">
     <div className="row">
        <div className="col-md-8 col-sm-12 snd-banner">
          <div class="col-md-7">
            <h3 class="heading-wrapper" style={{textAlign: 'left'}}>About Kaushalam</h3>

            <p class="theme-p-tag">
              Experiential online retreat with emphasis on practice and
              continuous connect to our Inner Being, that includes:
            </p>
            <ul>
              <li class="ml-4 gotham-light">
                Facilitated Sessions by Experts
              </li>
              <li class="ml-4 gotham-light">
                Inspirational Audio, Video &amp; Reading
              </li>
              <li class="ml-4 gotham-light">
                Powerful Peer to Peer, Group Learning
              </li>
              <li class="ml-4 gotham-light">
                Self Reflection &amp; Contemplation Zones
              </li>
            </ul>
          </div>
          
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <div class="d-flex img-center">
            <img
              className="img-fluid"
              src={kaushlammeditation}
              alt="Wellness banner"
            />
          </div>
        </div>
      </div>
         </div>   
     
    </div>
  );
}
